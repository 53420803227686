
.modal-footer {
  padding: 15px 0 0;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  button {
    padding: 10px 15px;
    background: #6c757d;
    color: #fff;
    border-radius: 2px;
    font-size: 16px;
  }
}

span.double-divider-span {
  display: grid;
  grid-template-columns: 56px 32px;
  justify-content: center;
  > span + span {
    text-align: left;
  }
}
.modal-content {
  .badge {
    min-width: 16px;
    width: 16px !important;
    height: 16px !important;
    border-radius: 500px;
    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 6px;
    line-height: 7px;
    display: inline;
    justify-content: center;
    align-items: center;
    background-color: red;
    margin-left: 10px;
  }
  max-height: calc(100vh - 100px);
  overflow-y: auto;

  td {
    .arrowspan {
      width: 12px !important;
      img {
        width: 100%;
        max-width: 12px;
      }
    }
  }

  .body {
    &.oops_mode {
      font-size: 16px;
      font-weight: 500;
      color: #2e305c;
      max-width: 523px;
      flex-wrap: wrap;
      margin: 0 auto;
      -webkit-flex-wrap: wrap;
      .image {
        max-width: 60px;
        margin: 0 auto;

        img {
          max-width: 100%;
        }
      }
      .text-data {
        margin-top: 15px;
        line-height: 35px;
        font-size: 20px;
        text-align: center;
        width: 100%;
        &::v-deep span {
          background: #fe646f;
          padding: 5px 10px;
          border-radius: 3px;
          color: #fff;
          margin: 0 5px;
        }
      }
    }
  }
}

.notfound {
  font-weight: bold;
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
}

.text-right {
  text-align: right;
}
.content {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px;
  margin-top: 16px;
  .notfound {
    font-weight: bold;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #2e305c;
    margin-bottom: 12px;
  }
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    tbody {
      tr {
        th {
          text-align: center;
          padding: 10px 16px;
          font-style: normal;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          border-top: 0.5px solid #e0e0e0;
          border-right: 0.5px solid #e0e0e0;
          border-bottom: 0.5px solid #e0e0e0;
          white-space: nowrap;
          @media screen and (max-width: 959px) {
            font-size: 14px !important;
          }
          &.date {
            width: 18%;
          }
          &.three,
          &.five {
            width: 30%;
          }
          &:first-child {
            border-left: 0.5px solid #e0e0e0;
          }
        }
        td {
          text-align: center;
          padding: 12px 12px;
          font-style: normal;
          font-size: 15px;
          line-height: 18px;
          color: #000000;
          border-right: 0.5px solid #e0e0e0;
          border-bottom: 0.5px solid #e0e0e0;
          white-space: nowrap;
          @media screen and (max-width: 959px) {
            font-size: 14px !important;
            font-weight: 500;
          }

          &.date {
            width: 18%;
          }
          &.three,
          &.five {
            width: 30%;
          }
          &:first-child {
            border-left: 0.5px solid #e0e0e0;
          }
        }
        &:nth-child(odd) {
          background: #fafafa;
        }
        &:first-child {
          td {
            border-top: 0.5px solid #e0e0e0;
            &:first-child {
              border-top-left-radius: 4px;
            }
            &:last-child {
              border-top-right-radius: 4px;
            }
          }
        }
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 4px;
            }
            &:last-child {
              border-bottom-right-radius: 4px;
            }
          }
        }

        .three-points,
        .five-points,
        .money-line,
        .total {
          .texts {
            cursor: pointer;
          }
          .greenclass {
            color: #0db702;
          }
          .redclass {
            color:#b72f02;
          }
        }
      }
    }
  }
}

.my-pending-picks-detail-modal {
  z-index: 9999;
  .modal-content {
    table.new-modal-design {
      margin-top: 15px;
      thead {
        tr {
          th {
            background: #2e305c !important;
            color: #fff;
            font-size: 18px;
            border-radius: 0px !important;
            padding: 12px 14px;
            border: none;
            .lamp {
              float: right;
              cursor: pointer;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            border-color: #c8c8c8;
            padding: 9px 14px !important;
            color: #000;
            vertical-align: middle;
            font-size: 16px !important;
            font-weight: 500;
            white-space: initial;
            @media all and (max-width: 600px) {
              white-space: nowrap;
            }
            span.check-img {
              display: inline-block;
              line-height: 0;
              max-width: 22px;
              margin-left: 10px;
              position: relative;
              top: 2px;
              img {
                max-width: 100%;
              }
            }
            span.success {
              color: #20a013;
              font-weight: 600 !important;
            }
            span.lose {
              color: #e40808;
              font-weight: 600 !important;
            }
            &.orange {
              background: #fa8c28;
              color: #fff;
            }
            &.sky-blue {
              background: #648cff;
              color: #fff;
            }
            &:nth-child(1) {
              width: 18%;
            }
            &:nth-child(2) {
              width: 35%;
            }
            &:nth-child(3) {
              width: 35%;
            }
          }

          &:nth-child(odd) {
            background: #fff;
          }
          &:last-child {
            td {
              border-bottom: 0.5px solid #c8c8c8;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .content {
    margin-top: 16px;
    table {
      tbody {
        tr {
          td {
            &.date,
            &.three,
            &.five {
              width: 33.33%;
            }
          }
        }
      }
    }
  }
}
